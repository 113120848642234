<template>
  <div class="container-fluid px-0 py-2">
    <div class="topo-itens">
      <div>
        <router-link :to="{ name: 'KitUnityV2' }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-left"
            style="opacity: 0.5"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </router-link>
      </div>
      <div>
        <h3 style="font-weight: 600">
          Valor da personalização: <strong>R$ {{ formatMoney(total) }}</strong>
        </h3>
      </div>
    </div>
    <div>
      <div>
        <div class="col-12 col-md-12 mx-auto">
          <div class="row">
            <!-- <div class="col-12">
              <div class="row pt-4">
                <div
                  class="col-6 px-0 py-2 aos-init mb-2"
                  data-aos="fade"
                  data-aos-delay="100"
                >
                  <router-link :to="{ name: 'KitUnityV2' }" class="pl-4">
                    <img
                      src="~@/assets/img/botao-voltar.png"
                      class="aos-init"
                      height="35"
                      data-aos="fade"
                      data-aos-delay="300"
                      alt="Qsuíte"
                      style="opacity: 0.5"
                    />
                  </router-link>
                </div>
                <div
                  class="col-6 text-right aos-init"
                  data-aos="fade"
                  data-aos-delay="100"
                >
                  <h3 style="font-weight: 600">
                    {{ triggers.length ? titleStep : null }}
                  </h3>
                </div>
              </div>
            </div> -->
<!-- Button trigger modal -->



            <div v-if="trigger">
              <div class="w-100 aos-init" data-aos="fade" data-aos-delay="300">
                <div class="grid-item">
                  <div class="unidade-item">
                    <div v-if="unity.number" class="unidade-">
                      <img
                        :src="enterprise ? enterprise.image : null"
                        alt=""
                        style="max-width: 150px"
                      />
                      <div
                        class="img-amplia"
                        v-if="unity.tipology && unity.tipology.image"
                      >
                        <img
                          :src="unity.tipology.image"
                          style="max-width: 100%"
                          alt=""
                          class="img-tipology"
                        />
                        <a
                          :href="unity.tipology.image"
                          target="_blank"
                          class="open-img"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-search"
                          >
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                          </svg>
                        </a>
                      </div>

                      <div style="min-width: 270px">
                        <div class="itens-dado">
                          <div class="item-dado">
                            <span>TIPO</span>
                            <p>
                              {{ unity.tipology ? unity.tipology.name : null }}
                            </p>
                          </div>

                          <!-- <div class="item-dado">
                            <span>METRAGEM</span>
                            <p>
                              {{ enterprise ? enterprise.area : null }}
                            </p>
                          </div> -->
                        </div>

                        <span class="unidade-number"
                          >UNIDADE {{ unity.number }}</span
                        >
                        <p class="item-descricao">
                          {{
                            unity.tipology ? unity.tipology.description : null
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="conteudo-item"
                    v-show="!loading"
                    data-anima="bottom"
                  >
                    <div class="title-legenda">
                      {{ triggers.length ? titleStep : null }}
                    </div>
                    <div class="grid-conteudo">
                      <div>
                        <div v-if="personalization_type_selected">
                          <!-- <div v-if="personalization_type_selected.personalization_types_subitems.length < 1"> -->
                            <div
                              class="img-amplia stand-img"                         
                              v-if="
                                personalization_type_selected.personalization_type
                                  .image
                              "
                            >
                              <img
                              @mouseover="showImageMark(personalization_type_selected)"
                              @mouseout="removeImageMark(personalization_type_selected)"
                                class="img-stand"
                                id="image3"
                                :src="
                                  item_selected != ''
                                    ? item_selected.image3 ||
                                      personalization_type_selected
                                        .personalization_type.image
                                    : personalization_type_selected
                                        .personalization_type.image
                                "
                                alt=""
                                style="
                                  max-width: 100%;
                                  border-radius: 22px;
                                  max-height: 350px;
                                "
                              />
                              <!-- :href="
                                  item_selected && 
                                  item_selected.image3 != null  
                                    ? item_selected.image3 ||
                                      personalization_type_selected
                                        .personalization_type.image                                      
                                    : personalization_type_selected
                                        .personalization_type.image
                                " -->
                              <a
                                @click="bigImage(item_selected, personalization_type_selected)"                              
                                data-bs-toggle="modal" 
                                data-bs-target="#exampleModal"
                                target="_blank"
                                class="open-img"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-search"
                                >
                                  <circle cx="11" cy="11" r="8"></circle>
                                  <line
                                    x1="21"
                                    y1="21"
                                    x2="16.65"
                                    y2="16.65"
                                  ></line>
                                </svg>
                              </a>
                            </div>

                            <p
                              style="
                                font-size: 14px;
                                color: #2f2f2f80;
                                margin-top: 10px;
                              "
                              class="ml-sm-5"
                              v-html="quebraLinha( triggers[step].subtitle)"
                            >
                            </p>
                          <!-- </div> -->
                  
                        <span
                          v-if="!personalization_type_selected"
                          class="selecione-item"
                        >
                          <i class="fas fa-exclamation-triangle mr-3"></i
                          >Selecione um item</span
                        >
                      </div>
                        </div>
                        
                      <b-form @submit.prevent="saveItens" class="form-item">
                        <div v-if="trigger">
                          <div>
                            <!-- <label>{{ trigger.name }}</label> -->
                            <label>TIPO PERSONALIZAÇÃO</label>
                            
                            <b-form-select
                              v-validate="'required'"
                              v-model="select_stand"
                              @change="selectStandItem(select_stand)"
                              name="bancada"
                              :class="{
                                'is-invalid': errors.has('bancada'),
                              }"
                            >
                            <option selected="true" value="">Selecione</option>
                              <option
                                v-for="item in trigger.itens"
                                :key="item.kit_config_id"
                                :name="item.personalization_type.name"
                                :value="item.kit_config_id"
                              >
                                {{ item.personalization_type.name }}
                              </option>
                            </b-form-select>
                          </div>
                          <div>
                            <!-- <label>TIPO PERSONALIZAÇÃO</label> -->
                            <label>{{
                              personalization_type_selected.name
                            }}</label>
                            <b-form-select
                              v-validate="'required'"
                              v-model="item_selected"
                              name="pedra"
                              :class="{
                                'is-invalid': errors.has('pedra'),
                              }"
                            >
                              <option value="" selected="true">Selecione</option>
                              <option
                                v-for="item in personalization_type_item_options"
                                :key="
                                  item.kit_config_id_of_type_personalization_type_item
                                "
                                :name="item.personalization_type_item.name"
                                :value="item.personalization_type_item"
                              >
                                {{ item.personalization_type_item.name }}
                              </option>
                            </b-form-select>
                          </div>

                          <div
                            class="detalhes-stone"
                            v-if="item_selected"
                            data-anima="bottom"
                          >
                            <img
                              :src="item_selected.image"
                              alt=""
                              style="
                                width: 100px;
                                height: 100px;
                                border-radius: 14px;
                              "
                            />
                            <div>
                              <p>
                                {{
                                  personalization_type_selected
                                    ? personalization_type_selected
                                        .personalization_type.name
                                    : ""
                                }}
                              </p>
                              <p>{{ item_selected.name }}</p>
                              <span
                                >R$ {{ formatMoney(item_selected.price) }}</span
                              >
                            </div>
                          </div>
                        </div>

                        <b-button
                        @click="changePersonalizationCurrent()"
                          type="submit"
                          :disabled="!personalization_type_selected"
                          >{{
                            !personalization_type_selected
                              ? "SELECIONE UM ITEM"
                              : "ADICIONAR"
                          }}</b-button
                        >
                        <!-- <b-button
                        class="nointerest"                         
                          @click="goNextPersonalization()" 
                          >NÃO TENHO INTERESSE
                        </b-button> -->
                      </b-form>

                      
                       
                      </b-form>
                    </div>
                    <BaseClientKitConditions
                      :new_style="true"
                      class="condicoes"
                      v-show="trigger"
                    />
                  </div>
                  <div
                    class="conteudo-item"
                    style="display: flex; margin-top: 50px"
                    data-anima="top"
                    v-if="loading"
                  >
                    <div
                      class="col-12 cancela-col-padding"
                      style="text-align: center"
                    >
                      <div class="loading">
                        <b-spinner label="Loading..."></b-spinner>
                      </div>
                    </div>
                  </div>
                </div>

                <h3
                  class="mb-1 font-15 font-b-5 pt-0 mb-2"
                  style="
                    color: #8d8d8d;
                    line-height: 20px;
                    text-transform: uppercase;
                  "
                >
                  {{ subTitleStep }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
          <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
           <p class="float-right text-white" style="font-size: 23px; cursor:pointer" data-bs-dismiss="modal">x</p>
          <div class="modal-content">
            <div class="">
              <img id="modalimagepersonalization" style="width: 100%" />
            </div>        
          </div>
        </div>
      </div>  
  </div>


  
</template>
<script>
import KitConfigService from "@/services/resources/KitConfigService";
import TriggerService from "@/services/resources/TriggerService";
import Money from "@/mixins/money";
const serviceTrigger = TriggerService.build();
const service = KitConfigService.build();

export default {
  mixins: [Money],
  props: {
    unity_id: {
      type: Number,
    },
    enterprise_id: {
      type: Number,
    },
    tipology_id: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      select_stand: null,
      stand: null,
      stone: null,
      headers: [
        {
          title: "Selecione o tipo de bancada",
          description:
            "A opção com extensão de bancada deve ser utilizado com cooktop",
        },
        {
          title: "Selecione o tipo de pedra",
          description: "QUANDO ESCOLHEREM COM EXTENSÃO DE BANCADA",
        },
        {
          title: "Selecione o nicho do banhos",
          description: null,
        },
        {
          title: "Selecione o tipo de pedra",
          description:
            "QUANDO ESCOLHEREM O NICHO TODAS AS PEDRAS DO AMBIENTE SERÃO ALTERADAS",
        },
      ],
      stands: [],
      stones: [],
      stones_niches: [],
      niches: [],
      triggers: [],
      trigger: null,
      item: {
        name: null,
        subtitle: null,
      },
      isStone: false,
      step: 0,
      order: {
        unity_id: null,
        enterprise_id: null,
        tipology_id: null,
        itens: [],
      },
      unity: {
        number: null,
        tipology: {
          name: null,
          description: null,
        },
      },
      item_selected_current: 0,
      titleStep: null,
      subTitleStep: null,
      enterprise: null,
      personalization_type_selected: null,
      personalization_type_item_options: [],
      item_selected: "",
    };
  },
  computed: {
    total() {
      let sum = 0;
      sum += this.personalization_type_selected
        ? this.personalization_type_selected.personalization_type.price || 0
        : 0;
      sum += this.item_selected != "" ? this.item_selected.price || 0 : 0;

      this.$emit("updateTotal", sum);

      return sum;
    },
    user() {
      return this.$store.getters.getProfile;
    },
    // trigger() {
    //   if (this.step == this.triggers.length) return null;
    //   else {
    //     const r = this.triggers[this.step];
    //     this.initStand(r.itens[0]);
    //     return r;
    //   }
    // },
  },
  methods: {
    initStand(item) {
      const trigger_item = item.personalization_type;
      this.select_stand = trigger_item.id;         

      // this.stand = trigger_item;
      this.personalization_type_selected = item;
      this.personalization_type_item_options = item.personalization_type_itens;
      this.item = trigger_item;
     // console.log(this.select_stand);   
    },
    goNextPersonalization() {
      this.item_selected_current =  1 + this.item_selected_current
      this.step++;
    },
    quebraLinha(str, is_xhtml = true) {
       var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
       return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    saveItens() {
   
      if (this.item_selected != "") {      
        this.item_selected_current =  1 + this.item_selected_current
        this.selectItem(this.personalization_type_selected);
        this.selectStone(this.item_selected);
     
        this.isStone = false;
        this.personalization_type_selected = null;
        this.item_selected = "";
         this.defineTrigger()
      }
    },
  defineTrigger(){
    if (this.step == this.triggers.length) return null;
      else {
        const r = this.triggers[this.step];
        this.initStand(r.itens[0]);
        this.trigger = r;
      }
  },
    selectStandItem(id_item) {
 
      this.item_selected = "";
      const item = this.trigger.itens.find((i) => {
        if(i.kit_config_id != null) {
          return i.kit_config_id == id_item
        }else{
         return i.kit_config_id_of_type_personalization_type == id_item;
        }  
      })
      
      console.log(item);
     this.personalization_type_item_options = [];
      // this.stand = item;
      this.personalization_type_selected = item;
      // this.stone = null;

      console.log(id_item);
      console.log(item);
     // console.log(item.personalization_type_itens);
      if (item && item.personalization_type_itens != null && item.personalization_type_itens.length) {
        this.isStone = true;
        // this.item = item;
        this.personalization_type_item_options =
          item.personalization_type_itens;
      } else if (item && item.personalization_type_items_subitems != null && item.personalization_type_items_subitems.length) {
        this.isStone = true;
        // this.item = item;
        this.personalization_type_item_options =
        item.personalization_types_items_subitems;
      }

      console.log( this.personalization_type_item_options);

    },
    bigImage(item_selected, personalization_type_selected) {
      let item = item_selected != "" && item_selected.image3 != null ? item_selected.image3 || 
      personalization_type_selected.personalization_type.image : 
      personalization_type_selected.personalization_type.image
      document.getElementById("modalimagepersonalization").src = item
 
    },
    changePersonalizationCurrent(current) {
      this.personalization_current = current
    },
    selectItem(item) {
      // let step = {
      //   item: item.item,
      // };

      this.order.itens.push(item);

      if (item.personalization_type_itens.length) {
        this.isStone = true;
        this.item = item.personalization_type_itens;
      } else {
        this.step++;
        this.item = item;
        if (this.step == this.triggers.length) {
          let order = {
            itens: this.order.itens,
            unity_id: this.order.unity_id,
            enterprise_id: this.order.enterprise_id,
            tipology_id: this.order.tipology_id,
            unity: this.unity,
            enterprise: this.enterprise,
          };
          localStorage.order = JSON.stringify(order);
          this.$router.push({
            name: "KitShopV2",
            params: order,
          });
          window.scrollTo(0, 0);
        }
      }

      this.titleStep = item.name;
      this.subTitle = item.subtitle;
    },
    selectStone(item) {
   

      console.log(this.trigger);
      console.log(this.personalization_type_selected);
      console.log(this.triggers);
      console.log(item);
      if (this.personalization_type_selected.personalization_types_subitems != null && this.personalization_type_selected.personalization_types_subitems.length >=  1) {
   
       let itens = []
          let itenstoshow = []
        if (this.personalization_type_selected.personalization_type_items_to_show_subitems != null) {
          itenstoshow = this.personalization_type_selected.personalization_type_items_to_show_subitems.split("-")
        }

        let filterforshow = itenstoshow.filter((filter) => {
          if(filter != ""){
            return filter
          }
        })

           let filters = filterforshow.filter((filter) => {
               if (filter == item.id) {
                    return filter
              }
          })

          
    console.log(item.id);
          console.log(filters);
        if (filters.length >= 1) {
            console.log(filterforshow);
           let filtersown = this.personalization_type_selected.personalization_types_subitems.filter((filter) => {
               if (this.personalization_type_selected.personalization_type.id == filter.father_personalization_types_id) {
                    return filter
              }
          })

            let count = 0
            console.log(this.personalization_type_selected.personalization_types_subitems);
               let trigger = this.trigger
               console.log(trigger);
            filtersown.forEach((element) => {
              element.personalization_type_itens = element.personalization_types_items_subitems
              element.personalization_type =  element.personalization_type
               element.kit_config_id = element.kit_config_id_of_type_personalization_type
              itens[itens.length] = element
  
              trigger = {
                company_id: trigger.company_id,
                created_at: element.created_at,
                deleted: element.deleted,
                id:trigger.id,
                itens: [element],
                name: element.name,
                order:trigger.order,
                subtitle:element.subtitle,
                tipologies:trigger.tipologies,
                updated_at:element.updated_at

              }
           
              console.log(element);
              console.log(this.trigger);
              //console.log(this.item_selected_current);      
             // trigger.subtitle = element.subtitle //"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa323"
             // trigger.name = element.name //"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
              // delete this.trigger.name
               //delete this.trigger.subtitle
              console.log(this.trigger.subtitle);
              console.log(this.trigger.name);
              console.log(this.trigger);     
           //   console.log(this.trigger);     
             // console.log(element);
              // this.trigger.itens = itens
              // this.trigger.subtitle = element.subtitle
              //console.log(this.triggers[this.step]);         
             // console.log(trigger); 
             // console.log(this.trigger);       
             
          //    trigger.subtitle = element.subtitle
          //  trigger.name = element.name
          //   console.log(element);
          //    trigger.itens = itens
          //     this.trigger.subtitle = element.subtitle
          //     console.log(this.triggers[this.step]);
         
          //     console.log(trigger); 
          //      count = 1 +count         
          //     this.trigger.name = count
          //    console.log(trigger);
          //     console.log(count);
          console.log(trigger);
              this.triggers.splice(this.step+1, 0, trigger);
            //  trigger = 
        console.log(this.triggers);
              //        this.triggers.splice(this.step, 0, this.trigger);
              // count = 1+count
              // this.trigger.subtitle = element.subtitle //"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa323"
              // this.trigger.name = element.name //"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
              // console.log(this.personalization_type_selected.personalization_types_subitems[id].name);
              // this.triggers[this.step + count].subtitle =   this.personalization_type_selected.personalization_types_subitems[id].subtitle 
              // this.triggers[this.step + count].name = this.personalization_type_selected.personalization_types_subitems[id].name
              // console.log(this.triggers);
  
            });  
            //console.log(  this.personalization_type_selected.personalization_types_subitems.length);
              //this.step = this.step+1
                    console.log(this.trigger);
//this.selectStandItem(  this.personalization_type_selected)      
    console.log(  this.personalization_type_selected);
           
        //    let trigger = this.trigger
          
           // trigger.itens = itens
          //  console.log(trigger);
            // if(this.step < this.triggers.length-1){
            //   this.triggers[this.triggers.length] = trigger
            // }else{
            //   this.triggers[this.triggers.length -1] = trigger
            // }
          
        //  this.triggers.splice(this.step -1, 0, trigger);
            // trigger.order = trigger.order+1
            // console.log(this.triggers);
            // console.log(this.triggers[this.step]);
            //  console.log(this.triggers[this.step +1]);
            //  console.log(this.triggers[this.triggers.length]);
            // this.triggers[this.step +1] = trigger
          
        

            console.log(this.triggers);
              this.$forceUpdate()
        }
      }
      this.order.itens[this.order.itens.length - 1].custom_item = item;
      this.step++;
      this.isStone = false;
      if (this.step == this.triggers.length) {
        let order = {
          itens: this.order.itens,
          unity_id: this.order.unity_id,
          enterprise_id: this.order.enterprise_id,
          tipology_id: this.order.tipology_id,
          unity: this.unity,
          enterprise: this.enterprise,
        };

        localStorage.order = JSON.stringify(order);
        this.$router.push({
          name: "KitShopV2",
          params: order,
        });
      } else {
        this.titleStep = this.triggers[this.step].name;
      }
    },
    selectStand(item) {
      this.order.itens.push(item);
      this.step = 1;
    },
    fetchKitConfig() {
      service
        .search()
        .then((resp) => {
          resp.forEach((element) => {
            if (element.type == "STAND") {
              this.stands.push(element);
            } else if (element.type == "NICHE") {
              this.niches.push(element);
            } else if (element.type == "STONE" && !element.is_niche) {
              this.stones.push(element);
            } else if (element.type == "STONE" && element.is_niche) {
              this.stones_niches.push(element);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchTrigger() {
      this.loading = true;
      let data = {
        tipology_id: this.order.tipology_id,
      };
      serviceTrigger
        .search(data)
        .then((resp) => {
          this.triggers = resp;
          console.log(resp);
          this.titleStep = resp[0].name;
          this.subTitle = resp[0].subtitle;
          this.defineTrigger()
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 300);
        });
    },

    // showImageMark(image){      
    //     if (this.item_selected != "") {
    //       if (this.item_selected.image3 !=null){
    //           document.getElementById("image3").src = this.item_selected.image3;
    //       }else{
    //         document.getElementById("image3").src = image.personalization_type.image;
    //       }
    //     } else {
    //       if (image.personalization_type.image2 != null && image.personalization_type.image2 != "") {
    //        document.getElementById("image3").src = image.personalization_type.image2;
    //       } else {
    //         document.getElementById("image3").src = image.personalization_type.image;
    //       }
    //    }

    //   },
    showImageMark(image){      
        if (this.item_selected != "") {
          document.getElementById("image3").src = this.item_selected.image3;
       } else {
          if (image.personalization_type.image2 != null && image.personalization_type.image2 != "") {
           document.getElementById("image3").src = image.personalization_type.image2;
          } else {
            document.getElementById("image3").src = image.personalization_type.image;
          }
       }

      },
      removeImageMark(image) {
        if (this.item_selected != "") {
          let item = this.item_selected !="" && this.item_selected.image3 != null ? this.item_selected.image3 ||  
          this.personalization_type_selected.personalization_type.image : this.personalization_type_selected.personalization_type.image
          document.getElementById("image3").src = this.item_selected.image3;
        } else {
          document.getElementById("image3").src = image.personalization_type.image;
        }    
    }
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    window.scrollTo({ top: 0, behavior: "smooth" });

    let order = JSON.parse(localStorage.order);

    this.order.unity_id = order.unity_id;
    this.order.enterprise_id = order.enterprise_id;
    this.order.tipology_id = order.tipology_id;
    this.unity = order.unity;
    this.enterprise = order.enterprise;

    // this.fetchKitConfig();
    this.fetchTrigger();
  },
};
</script>
<style>
body {
  background: #fff !important;
}
</style>
<style scoped>
.container-fluid {
  min-height: 80vh;
}
.grid-item {
  display: grid;
  grid-template-columns: 350px 1fr;
  margin: 0 auto;
  margin-top: -8px;
}
.unidade-item {
  background: rgba(244, 244, 246, 0.45);
  padding: 50px;
  min-height: 90vh;
}
.conteudo-item {
  background: #fff;
  padding: 40px;
  padding-top: 20px;
}
.itens-dado {
  border-bottom: 1px solid rgba(147, 147, 143, 0.3);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.item-dado span {
  color: rgba(147, 147, 143, 0.7);
  font-size: 11px;
  display: block;
  margin-bottom: 5px;
}
.item-dado p {
  color: rgba(46, 46, 46, 0.7);
  font-size: 14px;
}
.item-descricao {
  margin-top: 15px;
  color: rgba(46, 46, 46, 0.7);
  font-size: 13px;
  line-height: 1.5;
}
.unidade- {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.unidade-number {
  display: block;
  margin-top: 15px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color);
}
.grid-conteudo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  background: rgba(244, 244, 246, 0.5);
  padding: 30px 40px;
  padding-top: 0;
  padding-left: 0;
  border-radius: 22px;
}

form select {
  border-radius: 12px;
  height: 65px;
  color: #6c6c6c;
  margin-bottom: 10px;
}
form select:hover,
form select:focus {
  border-color: var(--color);
  box-shadow: none;
}
form button {
  width: 100%;
  border-radius: 12px;
  height: 65px;
  margin-top: 12px;
  background: var(--color);
  font-weight: 600;
}
form button:hover {
  background: var(--color);
  filter: brightness(0.95);
}
.nointerest {
   background: lightgray; 
   color: white
}
.nointerest:hover{
   background: var(--color);
   color: white
}
.detalhes-stone {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px auto;
}
.detalhes-stone p {
  margin: 0;
  margin-bottom: 5px;
  color: #2f2f2f;
  font-weight: 600;
  font-size: 12px;
  max-width: 95%;
}
.detalhes-stone span {
  display: block;
  font-weight: 600;
  font-size: 24px;
  color: var(--color);
}
.topo-itens {
  background: rgba(244, 244, 246, 0.5);
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  border-bottom: 1px solid #f4f4f6;
}
.topo-itens a,
.topo-itens h3 {
  margin: 0;
  color: #797979;
  margin-right: 10px;
}
.topo-itens h3 {
  font-size: 16px;
  font-weight: normal !important;
}
.img-amplia {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.img-amplia.stand-img {
  justify-content: flex-start;
  margin: 40px;
}
.img-amplia .open-img {
  position: absolute;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
}
.img-amplia img {
  min-width: 220px;
  min-height: 220px;
  background: rgba(244, 244, 246, 0.9);
}
.img-amplia.stand-img .open-img {
  bottom: 10px;
  left: 10px;
}
.form-item {
  padding-top: 30px;
}
.title-legenda {
  padding: 15px;
  text-align: center;
  color: var(--color);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  background: rgba(244, 244, 246, 0.5);
  margin-bottom: 10px;
  border-radius: 16px;
}
.condicoes {
  margin-top: 30px;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .grid-item,
  .grid-conteudo {
    grid-template-columns: 1fr;
  }
  .grid-conteudo {
    padding: 15px;
    gap: 0;
  }
  .unidade-item,
  .conteudo-item {
    padding: 20px;
    min-height: auto;
  }
  .unidade- img:first-child {
    margin: 0 auto;
  }
  .unidade- .img-tipology,
  .img-stand {
    max-width: 100% !important;
    height: 200px;
    object-fit: cover;
  }
  .topo-itens {
    padding: 20px;
    gap: 15px;
  }
  .topo-itens h3 {
    font-size: 14px;
  }
  form select,
  form button {
    height: 55px;
    font-size: 13px;
  }
  .img-amplia.stand-img {
    margin: 0;
  }
  .img-amplia img {
    min-width: 150px;
    min-height: 150px;
    height: 150px;
  }
}
</style>