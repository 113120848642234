import Rest from '@/services/Rest';

/**
 * @typedef {TriggerService}
 */
export default class TriggerService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/trigger'
}
